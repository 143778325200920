/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  POST_ORDERS_REQUEST,
  POST_ORDERS_SUCCESS,
  POST_ORDERS_FAILURE,
  ORDER_UPDATE_STATUS_FAILURE,
  ORDER_UPDATE_STATUS_REQUEST,
  ORDER_UPDATE_STATUS_SUCCESS,
  UPDATE_SINGLE_PRODUCT_IN_ORDER_REQUEST,
  UPDATE_SINGLE_PRODUCT_IN_ORDER_SUCCESS,
  UPDATE_SINGLE_PRODUCT_IN_ORDER_FAILURE,
  DELETE_SINGLE_PRODUCT_IN_ORDER_REQUEST,
  DELETE_SINGLE_PRODUCT_IN_ORDER_SUCCESS,
  DELETE_SINGLE_PRODUCT_IN_ORDER_FAILURE,
  DOWNLOAD_AGREEMENT_ORDER_REQUEST,
  DOWNLOAD_AGREEMENT_ORDER_SUCCESS,
  DOWNLOAD_AGREEMENT_ORDER_FAILURE,
  GET_PRIMEPRODUCT_REQUEST,
  GET_PRIMEPRODUCT_SUCCESS,
  GET_PRIMEPRODUCT_FAILURE,
  GET_ORDERDETAILSBYORDERID_REQUEST,
  GET_ORDERDETAILSBYORDERID_SUCCESS,
  GET_ORDERDETAILSBYORDERID_FAILURE,
  GET_ADMINUSERDETAILS_REQUEST,
  GET_ADMINUSERDETAILS_SUCCESS,
  GET_ADMINUSERDETAILS_FAILURE,
  GET_NEWPRIMEPRODUCT_REQUEST,
  GET_NEWPRIMEPRODUCT_SUCCESS,
  GET_NEWPRIMEPRODUCT_FAILURE,
} from "./ordersTypes";

import axios from "axios";
import Config from "../../config/index";
let lmsUrl = Config.lmsUrl;

const updateOrderStatusRequest = () => {
  return {
    type: ORDER_UPDATE_STATUS_REQUEST,
  };
};

const updateOrderStatusSuccess = (payload) => {
  return {
    type: ORDER_UPDATE_STATUS_SUCCESS,
    payload: payload,
  };
};

const updateOrderStatusFaliure = (error) => {
  return {
    type: ORDER_UPDATE_STATUS_FAILURE,
    payload: error,
  };
};

const updateOrderStatus = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(updateOrderStatusRequest());
    let url = `${lmsUrl}/orders_management/orders/update`;
    axios
      .put(url, payload, headers)
      .then(async (response) => {
        dispatch(updateOrderStatusSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateOrderStatusFaliure(err.message));
      });
  };
};

const ordersRequest = () => {
  return {
    type: GET_ORDERS_REQUEST,
  };
};

const ordersSuccess = (payload) => {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: payload,
  };
};

const ordersFaliure = (error) => {
  return {
    type: GET_ORDERS_FAILURE,
    payload: error,
  };
};

const orders = (token) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(ordersRequest());
    let url = `${lmsUrl}/orders_management/orders/list`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(ordersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(ordersFaliure(err.message));
      });
  };
};

const ordersCreateRequest = () => {
  return {
    type: POST_ORDERS_REQUEST,
  };
};

const ordersCreateSuccess = (payload) => {
  return {
    type: POST_ORDERS_SUCCESS,
    payload: payload,
  };
};

const ordersCreateFaliure = (error) => {
  return {
    type: POST_ORDERS_FAILURE,
    payload: error,
  };
};

const orderCreate = (token, payload) => {
  // const data = JSON.stringify()
  // data.append("clientId", payload.clientId)
  // data.append("companyName", payload.companyName)
  // data.append("agreement", payload.agreement)
  // data.append("status", payload.status)
  // data.append("adminUserName", payload.adminUserName)
  // data.append("adminUserEmail", payload.adminUserEmail)
  // data.append("partnerId", payload.partnerId)
  // data.append("partnerName", payload.partnerName)
  // data.append("partnerTerm", payload.partnerTerm)
  // data.append("partnerTermValue", payload.partnerTermValue)
  // data.append("products", JSON.stringify(payload.products))
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(ordersCreateRequest());
    let url = `${lmsUrl}/orders_management/orders/create`;
    return axios
      .post(url, payload, headers)
      .then(async (response) => {
        dispatch(ordersCreateSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(ordersCreateFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const updateSingleProductInOrderRequest = () => {
  return {
    type: UPDATE_SINGLE_PRODUCT_IN_ORDER_REQUEST,
  };
};

const updateSingleProductInOrderSuccess = (payload) => {
  return {
    type: UPDATE_SINGLE_PRODUCT_IN_ORDER_SUCCESS,
    payload: payload,
  };
};

const updateSingleProductInOrderFaliure = (error) => {
  return {
    type: UPDATE_SINGLE_PRODUCT_IN_ORDER_FAILURE,
    payload: error,
  };
};

const updateSingleProductInOrder = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(updateSingleProductInOrderRequest());
    let url = `${lmsUrl}/orders_management/orders/update`;
    return axios
      .put(url, payload, headers)
      .then(async (response) => {
        dispatch(updateSingleProductInOrderSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateSingleProductInOrderFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const deleteSingleProductInOrderRequest = () => {
  return {
    type: DELETE_SINGLE_PRODUCT_IN_ORDER_REQUEST,
  };
};

const deleteSingleProductInOrderSuccess = (payload) => {
  return {
    type: DELETE_SINGLE_PRODUCT_IN_ORDER_SUCCESS,
    payload: payload,
  };
};

const deleteSingleProductInOrderFaliure = (error) => {
  return {
    type: DELETE_SINGLE_PRODUCT_IN_ORDER_FAILURE,
    payload: error,
  };
};

const deleteSingleProductInOrder = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteSingleProductInOrderRequest());
    let url = `${lmsUrl}/orders_management/orders/delete_product`;
    return axios
      .put(url, payload, headers)
      .then(async (response) => {
        dispatch(deleteSingleProductInOrderSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteSingleProductInOrderFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const downloadRequest = () => {
  return {
    type: DOWNLOAD_AGREEMENT_ORDER_REQUEST,
  };
};

const downloadSuccess = (payload) => {
  return {
    type: DOWNLOAD_AGREEMENT_ORDER_SUCCESS,
    payload: payload,
  };
};

const downloadFaliure = (error) => {
  return {
    type: DOWNLOAD_AGREEMENT_ORDER_FAILURE,
    payload: error,
  };
};

const downloadFile = (token, order_id) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(downloadRequest());
    let url = `${lmsUrl}/orders_management/orders/order_form?order_id=${order_id}`;
    return axios
      .get(url, headers)
      .then(async (response) => {
        dispatch(downloadSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(downloadFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getPrimeProductRequest = () => {
  return {
    type: GET_PRIMEPRODUCT_REQUEST,
  };
};

const getPrimeProductSuccess = (payload) => {
  return {
    type: GET_PRIMEPRODUCT_SUCCESS,
    payload: payload,
  };
};

const getPrimeProductFaliure = (error) => {
  return {
    type: GET_PRIMEPRODUCT_FAILURE,
    payload: error,
  };
};

const getPrimeProductsByClientId = (token, clientId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getPrimeProductRequest());
    let url = `${lmsUrl}/subscriptions_management/subscription/get_prime_products_with_active_subscription?client_id=${clientId}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPrimeProductSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPrimeProductFaliure(err.message));
      });
  };
};

const getOrderDetailsByOrderIdRequest = () => {
  return {
    type: GET_ORDERDETAILSBYORDERID_REQUEST,
  };
};

const getOrderDetailsByOrderIdSuccess = (payload) => {
  return {
    type: GET_ORDERDETAILSBYORDERID_SUCCESS,
    payload: payload,
  };
};

const getOrderDetailsByOrderIdFaliure = (error) => {
  return {
    type: GET_ORDERDETAILSBYORDERID_FAILURE,
    payload: error,
  };
};

const getOrderDetailsByOrderId = (token, clientId, productId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getOrderDetailsByOrderIdRequest());
    let url = `${lmsUrl}/orders_management/orders/get_product_details?client_id=${clientId}&product_id=${productId}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getOrderDetailsByOrderIdSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getOrderDetailsByOrderIdFaliure(err.message));
      });
  };
};

const getAdminUserDetailsRequest = () => {
  return {
    type: GET_ADMINUSERDETAILS_REQUEST,
  };
};

const getAdminUserDetailsSuccess = (payload) => {
  return {
    type: GET_ADMINUSERDETAILS_SUCCESS,
    payload: payload,
  };
};

const getAdminUserDetailsFaliure = (error) => {
  return {
    type: GET_ADMINUSERDETAILS_FAILURE,
    payload: error,
  };
};

const getAdminUserDetails = (token, clientId, primePId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(getAdminUserDetailsRequest());
    let url = `${lmsUrl}/orders_management/orders/get_admin_user_details?client_id=${clientId}&product_id=${primePId}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAdminUserDetailsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAdminUserDetailsFaliure(err.message));
      });
  };
};

const getNEWPrimeProductRequest = () => {
  return {
    type: GET_NEWPRIMEPRODUCT_REQUEST,
  };
};

const getNEWPrimeProductSuccess = (payload) => {
  return {
    type: GET_NEWPRIMEPRODUCT_SUCCESS,
    payload: payload,
  };
};

const getNEWPrimeProductFaliure = (error) => {
  return {
    type: GET_NEWPRIMEPRODUCT_FAILURE,
    payload: error,
  };
};

const getNEWPrimeProductsByClientId = (token, clientId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getNEWPrimeProductRequest());
    let url = `${lmsUrl}/orders_management/orders/get_remainig_prime_products?client_id=${clientId}`;

    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getNEWPrimeProductSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getNEWPrimeProductFaliure(err.message));
      });
  };
};

export {
  orders,
  orderCreate,
  updateOrderStatus,
  updateSingleProductInOrder,
  deleteSingleProductInOrder,
  downloadFile,
  getPrimeProductsByClientId,
  getOrderDetailsByOrderId,
  getAdminUserDetails,
  getNEWPrimeProductsByClientId,
};

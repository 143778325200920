import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { green, pink } from "@material-ui/core/colors";
import { getProfile, setProfile } from "./../../store";
import Avatar from "@material-ui/core/Avatar";
import "../../assets/styles/delete.css";

const Navbar = ({ porfile, getProfile, setProfile, porfileSetReducer }) => {
  let data = JSON.parse(localStorage.getItem("lmsLoginDetails"));
  let token = data ? data.token : " ";
  let userId = data ? data.data["userId"] : " ";
  let profile1 = data ? data.profile : " ";
  useEffect(() => {
    getProfile(token, userId);
  }, [getProfile]);

  const [image, setImage] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const a = porfile.data.data;
  const avtar = data ? data.data["name"].toUpperCase() : "";

  const lastName = avtar ? avtar.split(" ")[1] : "";

  const lastname = lastName ? lastName.slice(0, 1).toUpperCase() : "";

  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(avtar.length);

    return color;
  }
  useEffect(() => {
    var { name, email, profile } = porfile.data.data ? porfile.data.data : {};
    setImage(profile);
    setemail(email);
    setname(name);
  }, [porfile]);

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  return (
    <>
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <a
            className="navbar-brand brand-logo-mini align-self-center d-lg-none"
            href="!#"
            onClick={(evt) => evt.preventDefault()}
          >
            <img src={image} alt="logo" style={{ height: "50px" }} />
          </a>
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <i className="mdi mdi-menu"></i>
          </button>

          {image ? (
            <>
              <ul
                className="navbar-nav navbar-nav-right ml-lg-auto"
                style={{ marginTop: "10px" }}
              >
                <li
                  className="nav-item  nav-profile border-0"
                  style={{ boxShadow: "none" }}
                >
                  <Dropdown alignRight>
                    <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                      <span className="profile-text">
                        {data !== null ? data.data.name : ""}
                      </span>

                      <>
                        {" "}
                        <img
                          src={image}
                          className="img-xs rounded-circle"
                          alt={
                            <Avatar>{avtar ? avtar.slice(0, 1) : " "}</Avatar>
                          }
                        />
                      </>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                      <Dropdown.Item
                        as={Link}
                        to="/profile"
                        className="dropdown-item preview-item d-flex align-items-center border-0 mt-2"
                      >
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/change_password"
                        className="dropdown-item preview-item d-flex align-items-center border-0"
                      >
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/logout"
                        className="dropdown-item preview-item d-flex align-items-center border-0"
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </>
          ) : (
            <>
              <ul
                className="navbar-nav navbar-nav-right ml-lg-auto"
                style={{ marginTop: "10px" }}
              >
                <li className="nav-item  nav-profile border-0">
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      className="nav-link count-indicator bg-transparent"
                      style={{ marginTop: "8px" }}
                    >
                      <span
                        className="profile-text"
                        style={{ marginLeft: "-50px", marginRight: "50px" }}
                      >
                        {data !== null ? data.data.name : ""}
                      </span>

                      <>
                        {" "}
                        <div
                          style={{
                            float: "right",
                            width: "50px",
                            marginTop: "-12px",
                            marginRight: "0px",
                            marginLeft: "-50px",
                          }}
                        >
                          <Avatar
                            className="img-xs rounded-circle"
                            style={{
                              width: "35px",
                              height: "35px",
                              fontSize: 18,
                              paddingTop: "2px",
                              // backgroundColor: "#ede7f6",
                              backgroundColor:"#fce4ec" , 
                              color:"red"
                              // color: "purple",
                            }}
                          >
                            {data !== null
                              ? data.data.name.slice(0, 1).toUpperCase() +
                                lastname
                              : ""}
                          </Avatar>
                        </div>
                      </>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                      <Dropdown.Item
                        as={Link}
                        to="/profile"
                        className="dropdown-item preview-item d-flex align-items-center border-0 mt-2"
                      >
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/change_password"
                        className="dropdown-item preview-item d-flex align-items-center border-0"
                      >
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={Link}
                        to="/logout"
                        className="dropdown-item preview-item d-flex align-items-center border-0"
                      >
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </>
          )}
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    porfile: state.porfile,
    porfileSetReducer: state.porfileSetReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token, userId) => dispatch(getProfile(token, userId)),
    setProfile: (payload, token) => dispatch(setProfile(payload, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,
  POST_CLIENTS_REQUEST,
  POST_CLIENTS_SUCCESS,
  POST_CLIENTS_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
} from "./clientsTypes";

import axios from "axios";
import Config from "../../config/index";
let lmsUrl = Config.lmsUrl;

const clientsRequest = () => {
  return {
    type: GET_CLIENTS_REQUEST,
  };
};

const clientsSuccess = (payload) => {
  return {
    type: GET_CLIENTS_SUCCESS,
    payload: payload,
  };
};

const clientsFaliure = (error) => {
  return {
    type: GET_CLIENTS_FAILURE,
    payload: error,
  };
};

const clients = (token) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(clientsRequest());
    let url = `${lmsUrl}/clients_management/clients/list`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(clientsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(clientsFaliure(err.message));
      });
  };
};

const addClientRequest = () => {
  return {
    type: POST_CLIENTS_REQUEST,
  };
};

const addClientSuccess = (payload) => {
  return {
    type: POST_CLIENTS_SUCCESS,
    payload: payload,
  };
};

const addClientFaliure = (error) => {
  return {
    type: POST_CLIENTS_FAILURE,
    payload: error,
  };
};

const addClient = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(addClientRequest());
    let url = `${lmsUrl}/clients_management/clients/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(addClientSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addClientFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const updateClientRequest = () => {
  return {
    type: UPDATE_CLIENT_REQUEST,
  };
};

const updateClientSuccess = (payload) => {
  return {
    type: UPDATE_CLIENT_SUCCESS,
    payload: payload,
  };
};

const updateClientFaliure = (error) => {
  return {
    type: UPDATE_CLIENT_FAILURE,
    payload: error,
  };
};

const updateClient = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(updateClientRequest());
    let url = `${lmsUrl}/clients_management/clients/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateClientSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateClientFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const deleteClientRequest = () => {
  return {
    type: DELETE_CLIENT_REQUEST,
  };
};

const deleteClientSuccess = (payload) => {
  return {
    type: DELETE_CLIENT_SUCCESS,
    payload: payload,
  };
};

const deleteClientFaliure = (error) => {
  return {
    type: DELETE_CLIENT_FAILURE,
    payload: error,
  };
};

const deleteClient = (token, client_id) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteClientRequest());
    let url = `${lmsUrl}/clients_management/clients/delete?client_id=${client_id}`;
    return axios
      .put(url, {}, headers)
      .then((response) => {
        dispatch(deleteClientSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteClientFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { clients, addClient, updateClient, deleteClient };

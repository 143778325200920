/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/
export const GET_PARTNERS_REQUEST = 'GET_PARTNERS_REQUEST'
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS'
export const GET_PARTNERS_FAILURE = 'GET_PARTNERS_FAILURE'

export const POST_PARTNERS_REQUEST = 'POST_PARTNERS_REQUEST'
export const POST_PARTNERS_SUCCESS = 'POST_PARTNERS_SUCCESS'
export const POST_PARTNERS_FAILURE = 'POST_PARTNERS_FAILURE'

export const UPDATE_PARTNER_REQUEST = 'UPDATE_PARTNER_REQUEST'
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS'
export const UPDATE_PARTNER_FAILURE = 'UPDATE_PARTNER_FAILURE'

export const DELETE_PARTNER_REQUEST = 'DELETE_PARTNER_REQUEST'
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS'
export const DELETE_PARTNER_FAILURE = 'DELETE_PARTNER_FAILURE'
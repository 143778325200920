/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

export const CREATE_MODULE_REQUEST = 'CREATE_MODULE_REQUEST'
export const CREATE_MODULE_SUCCESS = 'CREATE_MODULE_SUCCESS'
export const CREATE_MODULE_FAILURE = 'CREATE_MODULE_FAILURE'

export const GET_MODULES_REQUEST = 'GET_MODULES_REQUEST'
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS'
export const GET_MODULES_FAILURE = 'GET_MODULES_FAILURE'

export const GET_MODULESBYPRODUCTID_REQUEST = 'GET_MODULESBYPRODUCTID_REQUEST'
export const GET_MODULESBYPRODUCTID_SUCCESS = 'GET_MODULESBYPRODUCTID_SUCCESS'
export const GET_MODULESBYPRODUCTID_FAILURE = 'GET_MODULESBYPRODUCTID_FAILURE'

export const UPDATE_MODULE_REQUEST = 'UPDATE_MODULE_REQUEST'
export const UPDATE_MODULE_SUCCESS = 'UPDATE_MODULE_SUCCESS'
export const UPDATE_MODULE_FAILURE = 'UPDATE_MODULE_FAILURE'

export const DELETE_MODULE_REQUEST = 'DELETE_MODULE_REQUEST'
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS'
export const DELETE_MODULE_FAILURE = 'DELETE_MODULE_FAILURE'
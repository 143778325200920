/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  POST_ORDERS_REQUEST,
  POST_ORDERS_SUCCESS,
  POST_ORDERS_FAILURE,
  ORDER_UPDATE_STATUS_FAILURE,
  ORDER_UPDATE_STATUS_REQUEST,
  ORDER_UPDATE_STATUS_SUCCESS,
  UPDATE_SINGLE_PRODUCT_IN_ORDER_REQUEST,
  UPDATE_SINGLE_PRODUCT_IN_ORDER_SUCCESS,
  UPDATE_SINGLE_PRODUCT_IN_ORDER_FAILURE,
  DELETE_SINGLE_PRODUCT_IN_ORDER_REQUEST,
  DELETE_SINGLE_PRODUCT_IN_ORDER_SUCCESS,
  DELETE_SINGLE_PRODUCT_IN_ORDER_FAILURE,
  DOWNLOAD_AGREEMENT_ORDER_REQUEST,
  DOWNLOAD_AGREEMENT_ORDER_SUCCESS,
  DOWNLOAD_AGREEMENT_ORDER_FAILURE,
  GET_PRIMEPRODUCT_REQUEST,
  GET_PRIMEPRODUCT_SUCCESS,
  GET_PRIMEPRODUCT_FAILURE,
  GET_ORDERDETAILSBYORDERID_REQUEST,
  GET_ORDERDETAILSBYORDERID_SUCCESS,
  GET_ORDERDETAILSBYORDERID_FAILURE,
  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_STATUS_SUCCESS,
  SUBSCRIPTION_STATUS_FAILURE,
  GET_ADMINUSERDETAILS_REQUEST,
  GET_ADMINUSERDETAILS_SUCCESS,
  GET_ADMINUSERDETAILS_FAILURE,
  GET_NEWPRIMEPRODUCT_REQUEST,
  GET_NEWPRIMEPRODUCT_SUCCESS,
  GET_NEWPRIMEPRODUCT_FAILURE,
} from "./ordersTypes";

const initialState = {
  loading: false,
  orders: [],
  error: "",
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS_SUCCESS:
      return {
        loading: true,
        orders: action.payload,
        error: "",
      };
    case GET_ORDERS_FAILURE:
      return {
        loading: false,
        orders: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateCreateOrder = {
  loading: false,
  ordersCreate: {},
  error: "",
};

const ordersCreateReducer = (state = initialStateCreateOrder, action) => {
  switch (action.type) {
    case POST_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_ORDERS_SUCCESS:
      return {
        loading: true,
        ordersCreate: action.payload,
        error: "",
      };
    case POST_ORDERS_FAILURE:
      return {
        loading: false,
        ordersCreate: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateUpdateOrder = {
  loading: false,
  ordersCreate: {},
  error: "",
};

const updateOrderReducer = (state = initialStateUpdateOrder, action) => {
  switch (action.type) {
    case ORDER_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_UPDATE_STATUS_SUCCESS:
      return {
        loading: true,
        ordersCreate: action.payload,
        error: "",
      };
    case ORDER_UPDATE_STATUS_FAILURE:
      return {
        loading: false,
        ordersCreate: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateUpdateSingleProductInOrder = {
  loading: false,
  order: {},
  error: "",
};

const updateSingleProductInOrderReducer = (
  state = initialStateUpdateSingleProductInOrder,
  action
) => {
  switch (action.type) {
    case UPDATE_SINGLE_PRODUCT_IN_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SINGLE_PRODUCT_IN_ORDER_SUCCESS:
      return {
        loading: true,
        order: action.payload,
        error: "",
      };
    case UPDATE_SINGLE_PRODUCT_IN_ORDER_FAILURE:
      return {
        loading: false,
        order: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateDeleteSingleProductInOrder = {
  loading: false,
  order: {},
  error: "",
};

const deleteSingleProductInOrderReducer = (
  state = initialStateDeleteSingleProductInOrder,
  action
) => {
  switch (action.type) {
    case DELETE_SINGLE_PRODUCT_IN_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SINGLE_PRODUCT_IN_ORDER_SUCCESS:
      return {
        loading: true,
        order: action.payload,
        error: "",
      };
    case DELETE_SINGLE_PRODUCT_IN_ORDER_FAILURE:
      return {
        loading: false,
        order: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateDownloadFile = {
  loading: false,
  file: {},
  error: "",
};

const downloadFileReducer = (state = initialStateDownloadFile, action) => {
  switch (action.type) {
    case DOWNLOAD_AGREEMENT_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_AGREEMENT_ORDER_SUCCESS:
      return {
        loading: true,
        file: action.payload,
        error: "",
      };
    case DOWNLOAD_AGREEMENT_ORDER_FAILURE:
      return {
        loading: false,
        file: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPrimeProduct = {
  loading: false,
  primeproduct: [],
  error: "",
};

const primeProductsByClientIdReducer = (
  state = initialStateForPrimeProduct,
  action
) => {
  switch (action.type) {
    case GET_PRIMEPRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRIMEPRODUCT_SUCCESS:
      return {
        loading: true,
        primeproduct: action.payload,
        error: "",
      };
    case GET_PRIMEPRODUCT_FAILURE:
      return {
        loading: false,
        primeproduct: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForOrderDetailsByOrderId = {
  loading: false,
  orderDetailsByOrderId: [],
  error: "",
};

const orderDetailsByOrderIdReducer = (
  state = initialStateForOrderDetailsByOrderId,
  action
) => {
  switch (action.type) {
    case GET_ORDERDETAILSBYORDERID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERDETAILSBYORDERID_SUCCESS:
      return {
        loading: true,
        orderDetailsByOrderId: action.payload,
        error: "",
      };
    case GET_ORDERDETAILSBYORDERID_FAILURE:
      return {
        loading: false,
        orderDetailsByOrderId: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAdminUserDetails = {
  loading: false,
  adminUserDetails: [],
  error: "",
};

const getAdminUserDetailsReducer = (
  state = initialStateForAdminUserDetails,
  action
) => {
  switch (action.type) {
    case GET_ADMINUSERDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADMINUSERDETAILS_SUCCESS:
      return {
        loading: true,
        adminUserDetails: action.payload,
        error: "",
      };
    case GET_ADMINUSERDETAILS_FAILURE:
      return {
        loading: false,
        adminUserDetails: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForNEWPrimeProduct = {
  loading: false,
  newPrimeproduct: [],
  error: "",
};

const newprimeProductsByClientIdReducer = (
  state = initialStateForNEWPrimeProduct,
  action
) => {
  switch (action.type) {
    case GET_NEWPRIMEPRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_NEWPRIMEPRODUCT_SUCCESS:
      return {
        loading: true,
        newPrimeproduct: action.payload,
        error: "",
      };
    case GET_NEWPRIMEPRODUCT_FAILURE:
      return {
        loading: false,
        newPrimeproduct: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  ordersReducer,
  ordersCreateReducer,
  updateOrderReducer,
  updateSingleProductInOrderReducer,
  deleteSingleProductInOrderReducer,
  downloadFileReducer,
  primeProductsByClientIdReducer,
  orderDetailsByOrderIdReducer,
  getAdminUserDetailsReducer,
  newprimeProductsByClientIdReducer,
};

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import { GET_CLIENTS_REQUEST } from '../clients/clientsTypes'
import {
    LICENCE_DETAILS_REQUEST,
    LICENCE_DETAILS_SUCCESS,
    LICENCE_DETAILS_FAILURE,
    GENERATE_LICENCE_KEY_REQUEST,
    GENERATE_LICENCE_KEY_SUCCESS,
    GENERATE_LICENCE_KEY_FAILURE,
    GET_LICENCE_KEYS_REQUEST,
    GET_LICENCE_KEYS_SUCCESS,
    GET_LICENCE_KEYS_FAILURE,
    SHARE_KEY_REQUEST,
    SHARE_KEY_SUCCESS,
    SHARE_KEY_FAILURE
} from './licenceDetailsTypes'

const initialState = {
    loading: false,
    licenceDetails: [],
    error: ""
}

const licenceDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LICENCE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case LICENCE_DETAILS_SUCCESS:
            return {
                loading: true,
                licenceDetails: action.payload,
                error: ''
            }
        case LICENCE_DETAILS_FAILURE:
            return {
                loading: false,
                licenceDetails: [],
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateGenerateLicenseKey = {
    loading: false,
    licenceDetails: {},
    error: ""
}

const generateLicenseKeyReducer = (state = initialStateGenerateLicenseKey, action) => {
    switch (action.type) {
        case GENERATE_LICENCE_KEY_REQUEST:
            return {
                ...state,
                loading: false
            }
        case GENERATE_LICENCE_KEY_SUCCESS:
            return {
                loading: true,
                licenceDetails: action.payload,
                error: ''
            }
        case GENERATE_LICENCE_KEY_FAILURE:
            return {
                loading: false,
                licenceDetails: {},
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateGetLicenseKey = {
    loading: false,
    keys: {},
    error: ""
}

const getLicenseKeyReducer = (state = initialStateGetLicenseKey, action) => {
    switch (action.type) {
        case GET_LICENCE_KEYS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_LICENCE_KEYS_SUCCESS:
            return {
                loading: true,
                keys: action.payload,
                error: ''
            }
        case GET_LICENCE_KEYS_FAILURE:
            return {
                loading: false,
                keys: {},
                error: action.payload
            }
        default:
            return state
    }
}


const initialStateShareKey = {
    loading: false,
    keys: {},
    error: ""
}

const shareKeyReducer = (state = initialStateShareKey, action) => {
    switch (action.type) {
        case SHARE_KEY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SHARE_KEY_SUCCESS:
            return {
                loading: true,
                keys: action.payload,
                error: ''
            }
        case SHARE_KEY_FAILURE:
            return {
                loading: false,
                keys: {},
                error: action.payload
            }
        default:
            return state
    }
}



export { licenceDetailsReducer, generateLicenseKeyReducer, getLicenseKeyReducer, shareKeyReducer } 
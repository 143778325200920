/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  UPDATE_RENEWSUBSCRIPTION_REQUEST,
  UPDATE_RENEWSUBSCRIPTION_SUCCESS,
  UPDATE_RENEWSUBSCRIPTION_FAILURE,
  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_STATUS_SUCCESS,
  SUBSCRIPTION_STATUS_FAILURE,
} from "./subscriptionTypes";

const initialStateForRenewSubscription = {
  loading: false,
  renewSubscription: [],
  error: "",
};

const renewSubscriptionReducer = (
  state = initialStateForRenewSubscription,
  action
) => {
  switch (action.type) {
    case UPDATE_RENEWSUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_RENEWSUBSCRIPTION_SUCCESS:
      return {
        loading: true,
        renewSubscription: action.payload,
        error: "",
      };
    case UPDATE_RENEWSUBSCRIPTION_FAILURE:
      return {
        loading: false,
        renewSubscription: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateSubscriptionStatus = {
  loading: false,
  subscriptionStatus: {},
  error: "",
};

const changeSubscriptionStatusReducer = (
  state = initialStateSubscriptionStatus,
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SUBSCRIPTION_STATUS_SUCCESS:
      return {
        loading: true,
        subscriptionStatus: action.payload,
        error: "",
      };
    case SUBSCRIPTION_STATUS_FAILURE:
      return {
        loading: false,
        subscriptionStatus: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export { renewSubscriptionReducer, changeSubscriptionStatusReducer };

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  POST_PRODUCTS_REQUEST,
  POST_PRODUCTS_SUCCESS,
  POST_PRODUCTS_FAILURE,
  UPDATE_PRODUCTS_REQUEST,
  UPDATE_PRODUCTS_SUCCESS,
  UPDATE_PRODUCTS_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  GET_PRIMEPRODUCTS_REQUEST,
  GET_PRIMEPRODUCTS_SUCCESS,
  GET_PRIMEPRODUCTS_FAILURE,
  GET_SUBPRODUCTSOFPRIMEPRODUCT_REQUEST,
  GET_SUBPRODUCTSOFPRIMEPRODUCT_SUCCESS,
  GET_SUBPRODUCTSOFPRIMEPRODUCT_FAILURE,
  GET_PRODUCTDETAILSBYID_REQUEST,
  GET_PRODUCTDETAILSBYID_SUCCESS,
  GET_PRODUCTDETAILSBYID_FAILURE,
  UPDATE_ACTIVESTATUSOFPRODUCT_REQUEST,
  UPDATE_ACTIVESTATUSOFPRODUCT_SUCCESS,
  UPDATE_ACTIVESTATUSOFPRODUCT_FAILURE,
  GET_SUBPRODUCTFORPRIMEPRODUCT_REQUEST,
  GET_SUBPRODUCTFORPRIMEPRODUCT_SUCCESS,
  GET_SUBPRODUCTFORPRIMEPRODUCT_FAILURE,
  DELETE_SUBPRODUCTFORPRIMEPRODUCT_REQUEST,
  DELETE_SUBPRODUCTFORPRIMEPRODUCT_SUCCESS,
  DELETE_SUBPRODUCTFORPRIMEPRODUCT_FAILURE,
} from "./productsTypes";

import axios from "axios";
import Config from "../../config/index";
import { Alert } from "@material-ui/lab/Alert";
let lmsUrl = Config.lmsUrl;

const productsRequest = () => {
  return {
    type: GET_PRODUCTS_REQUEST,
  };
};

const productsSuccess = (payload) => {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: payload,
  };
};

const productsFaliure = (error) => {
  return {
    type: GET_PRODUCTS_FAILURE,
    payload: error,
  };
};

const addProductRequest = () => {
  return {
    type: POST_PRODUCTS_REQUEST,
  };
};

const addProductSuccess = (payload) => {
  return {
    type: POST_PRODUCTS_SUCCESS,
    payload: payload,
  };
};

const addProductFaliure = (error) => {
  return {
    type: POST_PRODUCTS_FAILURE,
    payload: error,
  };
};

const updateProductRequest = () => {
  return {
    type: UPDATE_PRODUCTS_REQUEST,
  };
};

const updateProductSuccess = (payload) => {
  return {
    type: UPDATE_PRODUCTS_SUCCESS,
    payload: payload,
  };
};

const updateProductFaliure = (error) => {
  return {
    type: UPDATE_PRODUCTS_FAILURE,
    payload: error,
  };
};

const deleteProductRequest = () => {
  return {
    type: DELETE_PRODUCT_REQUEST,
  };
};

const deleteProductSuccess = (payload) => {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: payload,
  };
};

const deleteProductFaliure = (error) => {
  return {
    type: DELETE_PRODUCT_FAILURE,
    payload: error,
  };
};

const products = (token) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(productsRequest());
    let url = `${lmsUrl}/products_management/products/list`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(productsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(productsFaliure(err.message));
      });
  };
};

const addProduct = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(addProductRequest());
    let url = `${lmsUrl}/products_management/products/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(addProductSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addProductFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const updateProduct = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(updateProductRequest());
    let url = `${lmsUrl}/products_management/products/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateProductSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateProductFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const deleteProduct = (token, product_id) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteProductRequest());
    let url = `${lmsUrl}/products_management/products/delete?product_id=${product_id}`;
    return axios
      .put(url, {}, headers)
      .then((response) => {
        dispatch(deleteProductSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteProductFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getPrimeProductsRequest = () => {
  return {
    type: GET_PRIMEPRODUCTS_REQUEST,
  };
};

const getPrimeProductsSuccess = (payload) => {
  return {
    type: GET_PRIMEPRODUCTS_SUCCESS,
    payload: payload,
  };
};

const getPrimeProductsFaliure = (error) => {
  return {
    type: GET_PRIMEPRODUCTS_FAILURE,
    payload: error,
  };
};

const getPrimeProducts = (token) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getPrimeProductsRequest());
    let url = `${lmsUrl}/products_management/products/get_prime_products`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getPrimeProductsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPrimeProductsFaliure(err.message));
      });
  };
};

const getSubProductsOfPrimeProductRequest = () => {
  return {
    type: GET_SUBPRODUCTSOFPRIMEPRODUCT_REQUEST,
  };
};

const getSubProductsOfPrimeProductSuccess = (payload) => {
  return {
    type: GET_SUBPRODUCTSOFPRIMEPRODUCT_SUCCESS,
    payload: payload,
  };
};

const getSubProductsOfPrimeProductFaliure = (error) => {
  return {
    type: GET_SUBPRODUCTSOFPRIMEPRODUCT_FAILURE,
    payload: error,
  };
};

const getSubProductsOfPrimeProduct = (token, parentId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(getSubProductsOfPrimeProductRequest());
    let url = `${lmsUrl}/products_management/products/get_sub_products?parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSubProductsOfPrimeProductSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSubProductsOfPrimeProductFaliure(err.message));
      });
  };
};

const getProductDetailsByIdRequest = () => {
  return {
    type: GET_PRODUCTDETAILSBYID_REQUEST,
  };
};

const getProductDetailsByIdSuccess = (payload) => {
  return {
    type: GET_PRODUCTDETAILSBYID_SUCCESS,
    payload: payload,
  };
};

const getProductDetailsByIdFaliure = (error) => {
  return {
    type: GET_PRODUCTDETAILSBYID_FAILURE,
    payload: error,
  };
};

const getProductDetailsById = (token, clientId, productId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getProductDetailsByIdRequest());
    let url = `${lmsUrl}/subscriptions_management/subscription/get_subscription_details_for_product?client_id=${clientId}&product_id=${productId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getProductDetailsByIdSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getProductDetailsByIdFaliure(err.message));
      });
  };
};

const changeActiveStatusOfProductRequest = () => {
  return {
    type: UPDATE_ACTIVESTATUSOFPRODUCT_REQUEST,
  };
};

const changeActiveStatusOfProductSuccess = (payload) => {
  return {
    type: UPDATE_ACTIVESTATUSOFPRODUCT_SUCCESS,
    payload: payload,
  };
};

const changeActiveStatusOfProductFaliure = (error) => {
  return {
    type: UPDATE_ACTIVESTATUSOFPRODUCT_FAILURE,
    payload: error,
  };
};

const changeActiveStatusOfProduct = (token, payload, productId, active) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(changeActiveStatusOfProductRequest());
    let url = `${lmsUrl}/products_management/products/change_active?productId=${productId}&active=${active}`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(changeActiveStatusOfProductSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(changeActiveStatusOfProductFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const getSubProductForPrimeProductRequest = () => {
  return {
    type: GET_SUBPRODUCTFORPRIMEPRODUCT_REQUEST,
  };
};

const getSubProductForPrimeProductSuccess = (payload) => {
  return {
    type: GET_SUBPRODUCTFORPRIMEPRODUCT_SUCCESS,
    payload: payload,
  };
};

const getSubProductForPrimeProductFaliure = (error) => {
  return {
    type: GET_SUBPRODUCTFORPRIMEPRODUCT_FAILURE,
    payload: error,
  };
};

const getSubProductForPrimeProduct = (token, orderId, parentId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getSubProductForPrimeProductRequest());
    let url = `${lmsUrl}/products_management/products/get_sub_productsByorderId?order_id=${orderId}&parent_id=${parentId}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getSubProductForPrimeProductSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSubProductForPrimeProductFaliure(err.message));
      });
  };
};

//  DELETE NON PRIME PRODUCT

const deleteSubProductForPrimeProductRequest = () => {
  return {
    type: DELETE_SUBPRODUCTFORPRIMEPRODUCT_REQUEST,
  };
};

const deleteSubProductForPrimeProductSuccess = (payload) => {
  return {
    type: DELETE_SUBPRODUCTFORPRIMEPRODUCT_SUCCESS,
    payload: payload,
  };
};

const deleteSubProductForPrimeProductFaliure = (error) => {
  return {
    type: DELETE_SUBPRODUCTFORPRIMEPRODUCT_FAILURE,
    payload: error,
  };
};
const deleteSubProductForPrimeProduct = (token, product_id) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteSubProductForPrimeProductRequest());
    let url = `${lmsUrl}/products_management/products/delete_sub_products?product_id==${product_id}`;
    return axios
      .put(url, {}, headers)
      .then((response) => {
        dispatch(deleteSubProductForPrimeProductSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteSubProductForPrimeProductFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export {
  products,
  getSubProductForPrimeProduct,
  deleteSubProductForPrimeProduct,
  addProduct,
  updateProduct,
  deleteProduct,
  getPrimeProducts,
  getSubProductsOfPrimeProduct,
  getProductDetailsById,
  changeActiveStatusOfProduct,
};

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import {
    LICENCE_DETAILS_REQUEST,
    LICENCE_DETAILS_SUCCESS,
    LICENCE_DETAILS_FAILURE,
    GENERATE_LICENCE_KEY_REQUEST,
    GENERATE_LICENCE_KEY_SUCCESS,
    GENERATE_LICENCE_KEY_FAILURE,
    GET_LICENCE_KEYS_REQUEST,
    GET_LICENCE_KEYS_SUCCESS,
    GET_LICENCE_KEYS_FAILURE,
    SHARE_KEY_REQUEST,
    SHARE_KEY_SUCCESS,
    SHARE_KEY_FAILURE
} from './licenceDetailsTypes'


import axios from 'axios';
import Config from '../../config/index'
let lmsUrl = Config.lmsUrl



const licenceDetailsRequest = () => {
    return {
        type: LICENCE_DETAILS_REQUEST
    }
}

const licenceDetailsSuccess = (payload) => {
    return {
        type: LICENCE_DETAILS_SUCCESS,
        payload: payload
    }
}

const licenceDetailsFaliure = (error) => {
    return {
        type: LICENCE_DETAILS_FAILURE,
        payload: error
    }
}


const getLicenceDetails = (token) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(licenceDetailsRequest())
        let url = `${lmsUrl}/keys_management/keys/order_reference_genrate_key`
        axios.get(url, headers).then(response => {
            dispatch(licenceDetailsSuccess(response.data))
        }).catch(err => {
            dispatch(licenceDetailsFaliure(err.message))
        })
    }
}


const generateLicenseKeyRequest = () => {
    return {
        type: GENERATE_LICENCE_KEY_REQUEST
    }
}

const generateLicenseKeySuccess = (payload) => {
    return {
        type: GENERATE_LICENCE_KEY_SUCCESS,
        payload: payload
    }
}

const generateLicenseKeyFaliure = (error) => {
    return {
        type: GENERATE_LICENCE_KEY_FAILURE,
        payload: error
    }
}


const generateLicenseKey = (token, payload) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(generateLicenseKeyRequest())
        let url = `${lmsUrl}/keys_management/keys/create`
        return axios.post(url, payload, headers).then(response => {
            dispatch(generateLicenseKeySuccess(response.data))
            return response.data
        }).catch(err => {
            dispatch(generateLicenseKeyFaliure(err.message))
            return err.message
        })
    }
}



const getLicenseKeyRequest = () => {
    return {
        type: GET_LICENCE_KEYS_REQUEST
    }
}

const getLicenseKeySuccess = (payload) => {
    return {
        type: GET_LICENCE_KEYS_SUCCESS,
        payload: payload
    }
}

const getLicenseKeyFaliure = (error) => {
    return {
        type: GET_LICENCE_KEYS_FAILURE,
        payload: error
    }
}


const getLicenseKey = (token, data) => {
    let productId = data.productId
    const params = new URLSearchParams({
        product_id: productId
    }).toString();
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(getLicenseKeyRequest())
        let url = `${lmsUrl}/keys_management/keys/get_keys_by_product_id?${params}`
        axios.get(url, headers).then(response => {
            dispatch(getLicenseKeySuccess(response.data))
        }).catch(err => {
            dispatch(getLicenseKeyFaliure(err.message))
        })
    }
}

const shareKeyRequest = () => {
    return {
        type: SHARE_KEY_REQUEST
    }
}

const shareKeySuccess = (payload) => {
    return {
        type: SHARE_KEY_SUCCESS,
        payload: payload
    }
}

const shareKeyFaliure = (error) => {
    return {
        type: SHARE_KEY_FAILURE,
        payload: error
    }
}



const shareKey = (token, payload) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(shareKeyRequest())
        let url = `${lmsUrl}/keys_management/keys/share_key`
        return axios.post(url, payload, headers).then(response => {
            dispatch(shareKeySuccess(response.data))
            return { status: true }
        }).catch(err => {
            dispatch(shareKeyFaliure(err.message))
            return { status: false }
        })
    }

}

export { getLicenceDetails, generateLicenseKey, getLicenseKey, shareKey } 

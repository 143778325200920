/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/
import {
    GET_MODULES_REQUEST,
    GET_MODULES_SUCCESS,
    GET_MODULES_FAILURE,
    GET_MODULESBYPRODUCTID_REQUEST,
    GET_MODULESBYPRODUCTID_SUCCESS,
    GET_MODULESBYPRODUCTID_FAILURE,
    CREATE_MODULE_REQUEST,
    CREATE_MODULE_SUCCESS,
    CREATE_MODULE_FAILURE,
    UPDATE_MODULE_REQUEST,
    UPDATE_MODULE_SUCCESS,
    UPDATE_MODULE_FAILURE,
    DELETE_MODULE_REQUEST,
    DELETE_MODULE_SUCCESS,
    DELETE_MODULE_FAILURE
} from './modulesTypes'
import axios from 'axios';
import Config from '../../config/index'
let lmsUrl = Config.lmsUrl

const getModulesRequest = () => {
    return {
        type: GET_MODULES_REQUEST
    }
}

const getModulesSuccess = (payload) => {
    return {
        type: GET_MODULES_SUCCESS,
        payload: payload
    }
}

const getModulesFaliure = (error) => {
    return {
        type: GET_MODULES_FAILURE,
        payload: error
    }
}

const getModules = (token) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(getModulesRequest())
        let url = `${lmsUrl}/modules_management/module/get`
        axios.get(url, headers).then(response => {
            dispatch(getModulesSuccess(response.data))
        }).catch(err => {
            dispatch(getModulesFaliure(err.message))
        })
    }
}

const getModulesByProductIdRequest = () => {
    return {
        type: GET_MODULESBYPRODUCTID_REQUEST
    }
}

const getModulesByProductIdSuccess = (payload) => {
    return {
        type: GET_MODULESBYPRODUCTID_SUCCESS,
        payload: payload
    }
}

const getModulesByProductIdFaliure = (error) => {
    return {
        type: GET_MODULESBYPRODUCTID_FAILURE,
        payload: error
    }
}

const getModulesByProductId = (token,productId) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(getModulesByProductIdRequest())
        let url = `${lmsUrl}/modules_management/module/get_modules_by_productId?productId=${productId}`
        axios.get(url, headers).then(response => {
            dispatch(getModulesByProductIdSuccess(response.data))
        }).catch(err => {
            dispatch(getModulesByProductIdFaliure(err.message))
        })
    }
}

const createModuleRequest = () => {
    return {
        type: CREATE_MODULE_REQUEST
    }
}

const createModuleSuccess = (payload) => {
    return {
        type: CREATE_MODULE_SUCCESS,
        payload: payload
    }
}

const createModuleFaliure = (error) => {
    return {
        type: CREATE_MODULE_FAILURE,
        payload: error
    }
}


const createModule = (token, payload) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(createModuleRequest())
        let url = `${lmsUrl}/modules_management/module/create`
        return axios.post(url, payload, headers).then(response => {
            dispatch(createModuleSuccess(response.data))
            return response.data
        }).catch(err => {
            dispatch(createModuleFaliure(err.message))
            return err.message
        })
    }
}

const updateModuleRequest = () => {
    return {
        type: UPDATE_MODULE_REQUEST
    }
}

const updateModuleSuccess = (payload) => {
    return {
        type: UPDATE_MODULE_SUCCESS,
        payload: payload
    }
}

const updateModuleFaliure = (error) => {
    return {
        type: UPDATE_MODULE_FAILURE,
        payload: error
    }
}

const updateModule = (token, payload) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(updateModuleRequest())
        let url = `${lmsUrl}/modules_management/module/update`
        return axios.put(url, payload, headers).then(response => {
            dispatch(updateModuleSuccess(response.data))
            return { status: true, data: response.data }
        }).catch(err => {
            dispatch(updateModuleFaliure(err.message))
            return { status: false, data: err.message }
        })
    }
}

const deleteModuleRequest = () => {
    return {
        type: DELETE_MODULE_REQUEST
    }
}

const deleteModuleSuccess = (payload) => {
    return {
        type: DELETE_MODULE_SUCCESS,
        payload: payload
    }
}

const deleteModuleFaliure = (error) => {
    return {
        type: DELETE_MODULE_FAILURE,
        payload: error
    }
}

const deleteModule = (token, moduleId) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(deleteModuleRequest())
        let url = `${lmsUrl}/modules_management/module/delete?moduleId=${moduleId}`
        return axios.put(url, {}, headers).then(response => {
            dispatch(deleteModuleSuccess(response.data))
            return { status: true, data: response.data }
        }).catch(err => {
            dispatch(deleteModuleFaliure(err.message))
            return { status: false, data: err.message }
        })
    }
}

export {
    getModules,
    getModulesByProductId,
    createModule,
    updateModule,
    deleteModule
}
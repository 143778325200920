/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  CUSTOM_KEY_REQUEST,
  CUSTOM_KEY_SUCCESS,
  CUSTOM_KEY_FAILURE,
} from "./customKeyTypes";

import axios from "axios";
import Config from "../../config/index";
let lmsUrl = Config.lmsUrl;

const customKeyRequest = () => {
  return {
    type: CUSTOM_KEY_REQUEST,
  };
};

const customKeySuccess = (payload) => {
  return {
    type: CUSTOM_KEY_SUCCESS,
    payload: payload,
  };
};

const customKeyFaliure = (error) => {
  return {
    type: CUSTOM_KEY_FAILURE,
    payload: error,
  };
};

const generateCustomKey = (token, data) => {
  let payload = {
    companyName: data.companyName,
    behalfOf: data.providingTo,
    uuid: data.systemUID,
    startDate: data.startDate,
    type: data.keyType,
  };
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(customKeyRequest());
    let url = `${lmsUrl}/keys_management/keys/create_temp`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(customKeySuccess(response.data));
        return { status: true };
      })
      .catch((err) => {
        dispatch(customKeyFaliure(err.message));
        return { status: false };
      });
  };
};

export { generateCustomKey };

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const POST_PRODUCTS_REQUEST = "POST_PRODUCTS_REQUEST";
export const POST_PRODUCTS_SUCCESS = "POST_PRODUCTS_SUCCESS";
export const POST_PRODUCTS_FAILURE = "POST_PRODUCTS_FAILURE";

export const UPDATE_PRODUCTS_REQUEST = "UPDATE_PRODUCTS_REQUEST";
export const UPDATE_PRODUCTS_SUCCESS = "UPDATE_PRODUCTS_SUCCESS";
export const UPDATE_PRODUCTS_FAILURE = "UPDATE_PRODUCTS_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const GET_PRIMEPRODUCTS_REQUEST = "GET_PRIMEPRODUCTS_REQUEST";
export const GET_PRIMEPRODUCTS_SUCCESS = "GET_PRIMEPRODUCTS_SUCCESS";
export const GET_PRIMEPRODUCTS_FAILURE = "GET_PRIMEPRODUCTS_FAILURE";

export const GET_SUBPRODUCTSOFPRIMEPRODUCT_REQUEST =
  "GET_SUBPRODUCTSOFPRIMEPRODUCT_REQUEST";
export const GET_SUBPRODUCTSOFPRIMEPRODUCT_SUCCESS =
  "GET_SUBPRODUCTSOFPRIMEPRODUCT_SUCCESS";
export const GET_SUBPRODUCTSOFPRIMEPRODUCT_FAILURE =
  "GET_SUBPRODUCTSOFPRIMEPRODUCT_FAILURE";

export const GET_PRODUCTDETAILSBYID_REQUEST = "GET_PRODUCTDETAILSBYID_REQUEST";
export const GET_PRODUCTDETAILSBYID_SUCCESS = "GET_PRODUCTDETAILSBYID_SUCCESS";
export const GET_PRODUCTDETAILSBYID_FAILURE = "GET_PRODUCTDETAILSBYID_FAILURE";

export const UPDATE_ACTIVESTATUSOFPRODUCT_REQUEST =
  "UPDATE_ACTIVESTATUSOFPRODUCT_REQUEST";
export const UPDATE_ACTIVESTATUSOFPRODUCT_SUCCESS =
  "UPDATE_ACTIVESTATUSOFPRODUCT_SUCCESS";
export const UPDATE_ACTIVESTATUSOFPRODUCT_FAILURE =
  "UPDATE_ACTIVESTATUSOFPRODUCT_FAILURE";

export const GET_SUBPRODUCTFORPRIMEPRODUCT_REQUEST =
  "GET_SUBPRODUCTFORPRIMEPRODUCT_REQUEST";
export const GET_SUBPRODUCTFORPRIMEPRODUCT_SUCCESS =
  "GET_SUBPRODUCTFORPRIMEPRODUCT_SUCCESS";
export const GET_SUBPRODUCTFORPRIMEPRODUCT_FAILURE =
  "GET_SUBPRODUCTFORPRIMEPRODUCT_FAILURE";

export const DELETE_SUBPRODUCTFORPRIMEPRODUCT_REQUEST =
  "DELETE_SUBPRODUCTFORPRIMEPRODUCT_REQUEST";
export const DELETE_SUBPRODUCTFORPRIMEPRODUCT_SUCCESS =
  "DELETE_SUBPRODUCTFORPRIMEPRODUCT_SUCCESS";
export const DELETE_SUBPRODUCTFORPRIMEPRODUCT_FAILURE =
  "DELETE_SUBPRODUCTFORPRIMEPRODUCT_FAILURE";

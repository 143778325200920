/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  UPDATE_RENEWSUBSCRIPTION_REQUEST,
  UPDATE_RENEWSUBSCRIPTION_SUCCESS,
  UPDATE_RENEWSUBSCRIPTION_FAILURE,
  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_STATUS_SUCCESS,
  SUBSCRIPTION_STATUS_FAILURE,
} from "./subscriptionTypes";

import axios from "axios";
import Config from "../../config/index";
let lmsUrl = Config.lmsUrl;

const renewSubscriptionRequest = () => {
  return {
    type: UPDATE_RENEWSUBSCRIPTION_REQUEST,
  };
};

const renewSubscriptionSuccess = (payload) => {
  return {
    type: UPDATE_RENEWSUBSCRIPTION_SUCCESS,
    payload: payload,
  };
};

const renewSubscriptionFaliure = (error) => {
  return {
    type: UPDATE_RENEWSUBSCRIPTION_FAILURE,
    payload: error,
  };
};

const renewSubscription = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(renewSubscriptionRequest());
    let url = `${lmsUrl}/subscriptions_management/subscription/renew_subscription`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(renewSubscriptionSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(renewSubscriptionFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const subcriptionStatusRequest = () => {
  return {
    type: SUBSCRIPTION_STATUS_REQUEST,
  };
};

const subcriptionStatusSuccess = (payload) => {
  return {
    type: SUBSCRIPTION_STATUS_SUCCESS,
    payload: payload,
  };
};

const subcriptionStatusFaliure = (error) => {
  return {
    type: SUBSCRIPTION_STATUS_FAILURE,
    payload: error,
  };
};

const changeSubscriptionStatus = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(subcriptionStatusRequest());
    let url = `${lmsUrl}/subscriptions_management/subscription/change_status`;
    axios
      .put(url, payload, headers)
      .then(async (response) => {
        dispatch(subcriptionStatusSuccess(response.data));
      })
      .catch((err) => {
        dispatch(subcriptionStatusFaliure(err.message));
      });
  };
};

export { renewSubscription, changeSubscriptionStatus };

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import {
    GET_PARTNERS_REQUEST,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILURE,
    POST_PARTNERS_REQUEST,
    POST_PARTNERS_SUCCESS,
    POST_PARTNERS_FAILURE,
    UPDATE_PARTNER_REQUEST,
    UPDATE_PARTNER_SUCCESS,
    UPDATE_PARTNER_FAILURE,
    DELETE_PARTNER_REQUEST,
    DELETE_PARTNER_SUCCESS,
    DELETE_PARTNER_FAILURE
} from './partnersTypes'

import axios from 'axios';
import Config from '../../config/index'
let lmsUrl = Config.lmsUrl

const partnersRequest = () => {
    return {
        type: GET_PARTNERS_REQUEST
    }
}

const partnersSuccess = (payload) => {
    return {
        type: GET_PARTNERS_SUCCESS,
        payload: payload
    }
}

const partnersFaliure = (error) => {
    return {
        type: GET_PARTNERS_FAILURE,
        payload: error
    }
}

const partners = (token) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(partnersRequest())
        let url = `${lmsUrl}/partners_management/partners/list`
        axios.get(url, headers).then(async response => {
            let masterPartners = await getMasterPartners(response.data.data)
            let data = {
                partners: response.data.data,
                masterPartners: masterPartners
            }
            dispatch(partnersSuccess(data))
        }).catch(err => {
            dispatch(partnersFaliure(err.message))
        })
    }
}

const getMasterPartners = (data) => {
    let masterPartners = []
    data.map(value => {
        if (value.partnerType === "Master Partner") {
            masterPartners.push(value.companyName)
        }
    })
    return masterPartners;
}

const addPartnerRequest = () => {
    return {
        type: POST_PARTNERS_REQUEST
    }
}

const addPartnerSuccess = (payload) => {
    return {
        type: POST_PARTNERS_SUCCESS,
        payload: payload
    }
}

const addPartnerFaliure = (error) => {
    return {
        type: POST_PARTNERS_FAILURE,
        payload: error
    }
}




const addPartner = (token, payload) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(addPartnerRequest())
        let url = `${lmsUrl}/partners_management/partners/create`
        return axios.post(url, payload, headers).then(response => {
            dispatch(addPartnerSuccess(response.data))
            return { status: true, data: response.data }
        }).catch(err => {
            dispatch(addPartnerFaliure(err.message))
            return { status: false, data: err.message }
        })
    }
}


const updatePartnerRequest = () => {
    return {
        type: UPDATE_PARTNER_REQUEST
    }
}

const updatePartnerSuccess = (payload) => {
    return {
        type: UPDATE_PARTNER_SUCCESS,
        payload: payload
    }
}

const updatePartnerFaliure = (error) => {
    return {
        type: UPDATE_PARTNER_FAILURE,
        payload: error
    }
}

const updatePartner = (token, payload) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(updatePartnerRequest())
        let url = `${lmsUrl}/partners_management/partners/update`
        return axios.put(url, payload, headers).then(response => {
            dispatch(updatePartnerSuccess(response.data))
            return { status: true, data: response.data }
        }).catch(err => {
            dispatch(updatePartnerFaliure(err.message))
            return { status: false, data: err.message }
        })
    }
}


const deletePartnerRequest = () => {
    return {
        type: DELETE_PARTNER_REQUEST
    }
}

const deletePartnerSuccess = (payload) => {
    return {
        type: DELETE_PARTNER_SUCCESS,
        payload: payload
    }
}

const deletePartnerFaliure = (error) => {
    return {
        type: DELETE_PARTNER_FAILURE,
        payload: error
    }
}

const deletePartner = (token, partner_id) => {
    let headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return (dispatch) => {
        dispatch(deletePartnerRequest())
        let url = `${lmsUrl}/partners_management/partners/delete?partner_id=${partner_id}`
        return axios.put(url, {}, headers).then(response => {
            dispatch(deletePartnerSuccess(response.data))
            return { status: true, data: response.data }
        }).catch(err => {
            dispatch(deletePartnerFaliure(err.message))
            return { status: false, data: err.message }
        })
    }
}


export { partners, addPartner, updatePartner, deletePartner } 

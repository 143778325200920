/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

export const POST_ORDERS_REQUEST = "POST_ORDERS_REQUEST";
export const POST_ORDERS_SUCCESS = "POST_ORDERS_SUCCESS";
export const POST_ORDERS_FAILURE = "POST_ORDERS_FAILURE";

export const ORDER_UPDATE_STATUS_REQUEST = "ORDER_UPDATE_STATUS_REQUEST";
export const ORDER_UPDATE_STATUS_SUCCESS = "ORDER_UPDATE_STATUS_SUCCESS";
export const ORDER_UPDATE_STATUS_FAILURE = "ORDER_UPDATE_STATUS_FAILURE";

export const UPDATE_SINGLE_PRODUCT_IN_ORDER_REQUEST =
  "UPDATE_SINGLE_PRODUCT_IN_ORDER_REQUEST";
export const UPDATE_SINGLE_PRODUCT_IN_ORDER_SUCCESS =
  "UPDATE_SINGLE_PRODUCT_IN_ORDER_SUCCESS";
export const UPDATE_SINGLE_PRODUCT_IN_ORDER_FAILURE =
  "UPDATE_SINGLE_PRODUCT_IN_ORDER_FAILURE";

export const DELETE_SINGLE_PRODUCT_IN_ORDER_REQUEST =
  "DELETE_SINGLE_PRODUCT_IN_ORDER_REQUEST";
export const DELETE_SINGLE_PRODUCT_IN_ORDER_SUCCESS =
  "DELETE_SINGLE_PRODUCT_IN_ORDER_SUCCESS";
export const DELETE_SINGLE_PRODUCT_IN_ORDER_FAILURE =
  "DELETE_SINGLE_PRODUCT_IN_ORDER_FAILURE";

export const DOWNLOAD_AGREEMENT_ORDER_REQUEST =
  "DOWNLOAD_AGREEMENT_ORDER_REQUEST";
export const DOWNLOAD_AGREEMENT_ORDER_SUCCESS =
  "DOWNLOAD_AGREEMENT_ORDER_SUCCESS";
export const DOWNLOAD_AGREEMENT_ORDER_FAILURE =
  "DOWNLOAD_AGREEMENT_ORDER_FAILURE";

export const GET_PRIMEPRODUCT_REQUEST = "GET_PRIMEPRODUCT_REQUEST";
export const GET_PRIMEPRODUCT_SUCCESS = "GET_PRIMEPRODUCT_SUCCESS";
export const GET_PRIMEPRODUCT_FAILURE = "GET_PRIMEPRODUCT_FAILURE";

export const GET_ORDERDETAILSBYORDERID_REQUEST =
  "GET_ORDERDETAILSBYORDERID_REQUEST";
export const GET_ORDERDETAILSBYORDERID_SUCCESS =
  "GET_ORDERDETAILSBYORDERID_SUCCESS";
export const GET_ORDERDETAILSBYORDERID_FAILURE =
  "GET_ORDERDETAILSBYORDERID_FAILURE";

export const GET_ADMINUSERDETAILS_REQUEST = "GET_ADMINUSERDETAILS_REQUEST";
export const GET_ADMINUSERDETAILS_SUCCESS = "GET_ADMINUSERDETAILS_SUCCESS";
export const GET_ADMINUSERDETAILS_FAILURE = "GET_ADMINUSERDETAILS_FAILURE";

export const GET_NEWPRIMEPRODUCT_REQUEST = "GET_NEWPRIMEPRODUCT_REQUEST";
export const GET_NEWPRIMEPRODUCT_SUCCESS = "GET_NEWPRIMEPRODUCT_SUCCESS";
export const GET_NEWPRIMEPRODUCT_FAILURE = "GET_NEWPRIMEPRODUCT_FAILURE";

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  POST_USERS_REQUEST,
  POST_USERS_SUCCESS,
  POST_USERS_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from "./usersTypes";

const initialState = {
  loading: false,
  users: {},
  error: "",
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        loading: true,
        users: action.payload,
        error: "",
      };
    case GET_USERS_FAILURE:
      return {
        loading: false,
        users: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAdd = {
  loading: false,
  userAdd: {},
  error: "",
};

const addUserReducer = (state = initialStateForAdd, action) => {
  switch (action.type) {
    case POST_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_USERS_SUCCESS:
      return {
        loading: true,
        userAdd: action.payload,
        error: "",
      };
    case POST_USERS_FAILURE:
      return {
        loading: false,
        userAdd: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdate = {
  loading: false,
  user: {},
  error: "",
};

const updateUserReducer = (state = initialStateForUpdate, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        loading: true,
        user: action.payload,
        error: "",
      };
    case UPDATE_USER_FAILURE:
      return {
        loading: false,
        user: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDelete = {
  loading: false,
  user: {},
  error: "",
};

const deleteUserReducer = (state = initialStateForDelete, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        loading: true,
        user: action.payload,
        error: "",
      };
    case DELETE_USER_FAILURE:
      return {
        loading: false,
        user: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export { usersReducer, addUserReducer, updateUserReducer, deleteUserReducer };

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,
  POST_CLIENTS_REQUEST,
  POST_CLIENTS_SUCCESS,
  POST_CLIENTS_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
} from "./clientsTypes";

const initialState = {
  loading: false,
  clients: {},
  error: "",
};

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENTS_SUCCESS:
      return {
        loading: true,
        clients: action.payload,
        error: "",
      };
    case GET_CLIENTS_FAILURE:
      return {
        loading: false,
        clients: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForAdd = {
  loading: false,
  clientAdd: {},
  error: "",
};

const addClientReducer = (state = initialStateForAdd, action) => {
  switch (action.type) {
    case POST_CLIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_CLIENTS_SUCCESS:
      return {
        loading: true,
        clientAdd: action.payload,
        error: "",
      };
    case POST_CLIENTS_FAILURE:
      return {
        loading: false,
        clientAdd: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdate = {
  loading: false,
  client: {},
  error: "",
};

const updateClientReducer = (state = initialStateForUpdate, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        loading: true,
        client: action.payload,
        error: "",
      };
    case UPDATE_CLIENT_FAILURE:
      return {
        loading: false,
        client: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDelete = {
  loading: false,
  client: {},
  error: "",
};

const deleteClientReducer = (state = initialStateForDelete, action) => {
  switch (action.type) {
    case DELETE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        loading: true,
        client: action.payload,
        error: "",
      };
    case DELETE_CLIENT_FAILURE:
      return {
        loading: false,
        client: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  clientsReducer,
  addClientReducer,
  updateClientReducer,
  deleteClientReducer,
};

import React, { Suspense, lazy } from "react";
import { isExpired, decodeToken } from "react-jwt";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
import { Provider } from "react-redux";
import store from "../store/store";
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Error404 = lazy(() => import("./user-pages/Error404"));
const Error500 = lazy(() => import("./user-pages/Error500"));
const Login = lazy(() => import("./user-management/login/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Clients = lazy(() => import("./clients/Clients"));
const Users = lazy(() => import("./users/Users"));
const MobillorUsers = lazy(() => import("./mobillorUsers/MobillorUsers"));
const Products = lazy(() => import("./products/Products"));
const Partners = lazy(() => import("./partners/Partners"));
const Orders = lazy(() => import("./orders/Orders"));
const GetKey = lazy(() => import("./get_key/GetKey"));
const ChangePassword = lazy(() =>
  import("./user-management/change-password/ChangePassword")
);
const LicenceDetails = lazy(() => import("./licence-details/LicenceDetails"));
const Logout = lazy(() => import("./user-management/logout/logout"));
const Profile = lazy(() => import("./user-management/profile/Profile"));
const Support = lazy(() => import("./support/Support"));
const Modules = lazy(() => import("./modules/Modules"));

const AppRoutes = () => {
  let data = JSON.parse(localStorage.getItem("lmsLoginDetails"));
  if (data) {
    let token = data.token;
    const isMyTokenExpired = isExpired(token);
    if (data.status && !isMyTokenExpired) {
      return (
        <Provider store={store}> 
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/modules" component={Modules} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/licence_details" component={LicenceDetails} />
              <Route exact path="/change_password" component={ChangePassword} />
              <Route exact path="/clients" component={Clients} />
              <Route exact path="/lmsusers" component={Users} />
              <Route exact path="/mobillorusers" component={MobillorUsers} />
              <Route exact path="/products" component={Products} />
              <Route exact path="/partners" component={Partners} />
              <Route exact path="/orders" component={Orders} />
              <Route exact path="/get_key" component={GetKey} />
              <Route exact path="/support" component={Support} />
              <Redirect to="/dashboard" />
            </Switch>
          </Suspense> 
        </Provider>
      ); 
    } else {
      return (
        <Provider store={store}>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path="/logout" component={Logout} />
              <Redirect to="/logout" />
            </Switch>
          </Suspense>
        </Provider>
      );
    }
  }

  return (
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/user-pages/register-1" component={Register1} />

          <Route path="/user-pages/error-404" component={Error404} />
          <Route path="/user-pages/error-500" component={Error500} />
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    </Provider>
  );
};

export default AppRoutes;

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  POST_PRODUCTS_REQUEST,
  POST_PRODUCTS_SUCCESS,
  POST_PRODUCTS_FAILURE,
  UPDATE_PRODUCTS_REQUEST,
  UPDATE_PRODUCTS_SUCCESS,
  UPDATE_PRODUCTS_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  GET_PRIMEPRODUCTS_REQUEST,
  GET_PRIMEPRODUCTS_SUCCESS,
  GET_PRIMEPRODUCTS_FAILURE,
  GET_SUBPRODUCTSOFPRIMEPRODUCT_REQUEST,
  GET_SUBPRODUCTSOFPRIMEPRODUCT_SUCCESS,
  GET_SUBPRODUCTSOFPRIMEPRODUCT_FAILURE,
  GET_PRODUCTDETAILSBYID_REQUEST,
  GET_PRODUCTDETAILSBYID_SUCCESS,
  GET_PRODUCTDETAILSBYID_FAILURE,
  UPDATE_ACTIVESTATUSOFPRODUCT_REQUEST,
  UPDATE_ACTIVESTATUSOFPRODUCT_SUCCESS,
  UPDATE_ACTIVESTATUSOFPRODUCT_FAILURE,
  GET_SUBPRODUCTFORPRIMEPRODUCT_FAILURE,
  GET_SUBPRODUCTFORPRIMEPRODUCT_SUCCESS,
  GET_SUBPRODUCTFORPRIMEPRODUCT_REQUEST,
} from "./productsTypes";

const initialState = {
  loading: false,
  products: [],
  error: "",
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        loading: true,
        products: action.payload,
        error: "",
      };
    case GET_PRODUCTS_FAILURE:
      return {
        loading: false,
        products: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForCreate = {
  loading: false,
  product: {},
  error: "",
};

const productCreateReducer = (state = initialStateForCreate, action) => {
  switch (action.type) {
    case POST_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_PRODUCTS_SUCCESS:
      return {
        loading: true,
        product: action.payload,
        error: "",
      };
    case POST_PRODUCTS_FAILURE:
      return {
        loading: false,
        product: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForUpdate = {
  loading: false,
  product: {},
  error: "",
};

const productUpdateReducer = (state = initialStateForUpdate, action) => {
  switch (action.type) {
    case UPDATE_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PRODUCTS_SUCCESS:
      return {
        loading: true,
        product: action.payload,
        error: "",
      };
    case UPDATE_PRODUCTS_FAILURE:
      return {
        loading: false,
        product: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForDelete = {
  loading: false,
  product: {},
  error: "",
};

const deleteUpdateReducer = (state = initialStateForDelete, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        loading: true,
        product: action.payload,
        error: "",
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        loading: false,
        product: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForPrimeProducts = {
  loading: false,
  primeproducts: [],
  error: "",
};

const primeProductsReducer = (state = initialStateForPrimeProducts, action) => {
  switch (action.type) {
    case GET_PRIMEPRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRIMEPRODUCTS_SUCCESS:
      return {
        loading: true,
        primeproducts: action.payload,
        error: "",
      };
    case GET_PRIMEPRODUCTS_FAILURE:
      return {
        loading: false,
        primeproducts: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSubProductsOfPrimeProduct = {
  loading: false,
  subProductsOfPrimeProduct: [],
  error: "",
};

const subProductsOfPrimeProductReducer = (
  state = initialStateForSubProductsOfPrimeProduct,
  action
) => {
  switch (action.type) {
    case GET_SUBPRODUCTSOFPRIMEPRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBPRODUCTSOFPRIMEPRODUCT_SUCCESS:
      return {
        loading: true,
        subProductsOfPrimeProduct: action.payload,
        error: "",
      };
    case GET_SUBPRODUCTSOFPRIMEPRODUCT_FAILURE:
      return {
        loading: false,
        subProductsOfPrimeProduct: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForProductDetailsById = {
  loading: false,
  productDetailsById: [],
  error: "",
};

const productDetailsByIdReducer = (
  state = initialStateForProductDetailsById,
  action
) => {
  switch (action.type) {
    case GET_PRODUCTDETAILSBYID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTDETAILSBYID_SUCCESS:
      return {
        loading: true,
        productDetailsById: action.payload,
        error: "",
      };
    case GET_PRODUCTDETAILSBYID_FAILURE:
      return {
        loading: false,
        productDetailsById: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForChangeActiveStatusOfProduct = {
  loading: false,
  changeActiveStatusOfProduct: {},
  error: "",
};

const changeActiveStatusOfProductReducer = (
  state = initialStateForChangeActiveStatusOfProduct,
  action
) => {
  switch (action.type) {
    case UPDATE_ACTIVESTATUSOFPRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ACTIVESTATUSOFPRODUCT_SUCCESS:
      return {
        loading: true,
        changeActiveStatusOfProduct: action.payload,
        error: "",
      };
    case UPDATE_ACTIVESTATUSOFPRODUCT_FAILURE:
      return {
        loading: false,
        changeActiveStatusOfProduct: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForgetSubProductForPrimeProduct = {
  loading: false,
  subNonPrimeProducts: [],
  error: "",
};

const getSubProductForPrimeProductReducer = (
  state = initialStateForgetSubProductForPrimeProduct,
  action
) => {
  switch (action.type) {
    case GET_SUBPRODUCTFORPRIMEPRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBPRODUCTFORPRIMEPRODUCT_SUCCESS:
      return {
        loading: true,
        subNonPrimeProducts: action.payload,
        error: "",
      };
    case GET_SUBPRODUCTFORPRIMEPRODUCT_FAILURE:
      return {
        loading: false,
        subNonPrimeProducts: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  productsReducer,
  productCreateReducer,
  productUpdateReducer,
  deleteUpdateReducer,
  primeProductsReducer,
  subProductsOfPrimeProductReducer,
  productDetailsByIdReducer,
  changeActiveStatusOfProductReducer,
  getSubProductForPrimeProductReducer,
};

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { combineReducers } from "redux";
import { loginReducer } from "./user-management/login/loginReducer";
import {
  clientsReducer,
  addClientReducer,
  updateClientReducer,
  deleteClientReducer,
} from "./clients/clientsReducer";

import {
  usersReducer,
  addUserReducer,
  updateUserReducer,
  deleteUserReducer,
} from "./users/usersReducer";

import { mobillorUsersReducer } from "./mobillorusers/mobillorUsersReducer";

import {
  productsReducer,
  productCreateReducer,
  productUpdateReducer,
  deleteUpdateReducer,
  primeProductsReducer,
  subProductsOfPrimeProductReducer,
  changeActiveStatusOfProductReducer,
  productDetailsByIdReducer,
  getSubProductForPrimeProductReducer,
} from "./products/productsReducer";

import {
  partnersReducer,
  addPartnerReducer,
  updatePartnerReducer,
  deletePartnerReducer,
} from "./partners/partnersReducer";
import {
  ordersReducer,
  ordersCreateReducer,
  updateOrderReducer,
  updateSingleProductInOrderReducer,
  deleteSingleProductInOrderReducer,
  downloadFileReducer,
  primeProductsByClientIdReducer,
  orderDetailsByOrderIdReducer,
  getAdminUserDetailsReducer,
  newprimeProductsByClientIdReducer,
} from "./orders/ordersReducer";
import { customKeyReducer } from "./custom-key/customKeyReducer";
import {
  licenceDetailsReducer,
  generateLicenseKeyReducer,
  getLicenseKeyReducer,
  shareKeyReducer,
} from "./licence-details/licenceDetailsReducer";
import { changePasswordReducer } from "./user-management/change-password/changePasswordReducer";
import {
  porfileReducer,
  porfileSetReducer,
} from "./user-management/profile/profileReducer";
import { getIssueReducer, updateIssueReducer } from "./Support/issueReducer";
import {
  getModulesReducer,
  createModuleReducer,
  updateModuleReducer,
  deleteModuleReducer,
  getModulesByProductIdReducer,
} from "./modules/modulesReducer";

import {
  renewSubscriptionReducer,
  changeSubscriptionStatusReducer,
} from "./subscription/subscriptionReducer";

const rootReducer = combineReducers({
  loginReducer: loginReducer,
  clients: clientsReducer,
  users: usersReducer,
  mobillorUsers: mobillorUsersReducer,
  products: productsReducer,
  partners: partnersReducer,
  orders: ordersReducer,
  addClient: addClientReducer,
  addUser: addUserReducer,
  addPartner: addPartnerReducer,
  customKey: customKeyReducer,
  productCreateReducer: productCreateReducer,
  ordersCreateReducer: ordersCreateReducer,
  licenceDetails: licenceDetailsReducer,
  changePasswordData: changePasswordReducer,
  porfile: porfileReducer,
  porfileSetReducer: porfileSetReducer,
  updateOrder: updateOrderReducer,
  generateLicenseKeyReducer: generateLicenseKeyReducer,
  getLicenseKeyReducer: getLicenseKeyReducer,
  shareKeyReducer: shareKeyReducer,
  issues: getIssueReducer,
  updateIssueReducer: updateIssueReducer,
  productUpdateReducer: productUpdateReducer,
  deleteUpdateReducer: deleteUpdateReducer,
  updatePartnerReducer: updatePartnerReducer,
  deletePartnerReducer: deletePartnerReducer,
  updateClientReducer: updateClientReducer,
  updateUserReducer: updateUserReducer,
  deleteClientReducer: deleteClientReducer,
  deleteUserReducer: deleteUserReducer,
  updateSingleProductInOrderReducer: updateSingleProductInOrderReducer,
  deleteSingleProductInOrderReducer: deleteSingleProductInOrderReducer,
  downloadFileReducer: downloadFileReducer,
  getModulesReducer: getModulesReducer,
  getModulesByProductIdReducer: getModulesByProductIdReducer,
  createModuleReducer: createModuleReducer,
  updateModuleReducer: updateModuleReducer,
  deleteModuleReducer: deleteModuleReducer,
  primeProductsByClientIdReducer: primeProductsByClientIdReducer,
  primeProductsReducer: primeProductsReducer,
  subProductsOfPrimeProductReducer: subProductsOfPrimeProductReducer,
  changeActiveStatusOfProductReducer: changeActiveStatusOfProductReducer,
  orderDetailsByOrderIdReducer: orderDetailsByOrderIdReducer,
  renewSubscriptionReducer: renewSubscriptionReducer,
  changeSubscriptionStatusReducer: changeSubscriptionStatusReducer,
  getAdminUserDetailsReducer: getAdminUserDetailsReducer,
  productDetailsByIdReducer: productDetailsByIdReducer,
  getSubProductForPrimeProductReducer: getSubProductForPrimeProductReducer,
  newprimeProductsByClientIdReducer: newprimeProductsByClientIdReducer,
});

export default rootReducer;

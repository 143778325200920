/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ISSUE_REQUEST,
  GET_ISSUE_SUCCESS,
  GET_ISSUE_FAILURE,
  UPDATE_ISSUE_REQUEST,
  UPDATE_ISSUE_SUCCESS,
  UPDATE_ISSUE_FAILURE,
} from "./issueTypes";

import axios from "axios";
import Config from "../../config/index";
let lmsUrl = Config.lmsUrl;

const getIssuesRequest = () => {
  return {
    type: GET_ISSUE_REQUEST,
  };
};

const getIssuesSuccess = (payload) => {
  return {
    type: GET_ISSUE_SUCCESS,
    payload: payload,
  };
};

const getIssuesFaliure = (error) => {
  return {
    type: GET_ISSUE_FAILURE,
    payload: error,
  };
};

const updateIssueRequest = () => {
  return {
    type: UPDATE_ISSUE_REQUEST,
  };
};

const updateIssueSuccess = (payload) => {
  return {
    type: UPDATE_ISSUE_SUCCESS,
    payload: payload,
  };
};

const updateIssueFaliure = (error) => {
  return {
    type: UPDATE_ISSUE_FAILURE,
    payload: error,
  };
};

const getIssues = (token) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getIssuesRequest());
    let url = `${lmsUrl}/issues_management/issues/get`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(getIssuesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getIssuesFaliure(err.message));
      });
  };
};

const updateIssue = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(updateIssueRequest());
    let url = `${lmsUrl}/issues_management/issues/update`;
    return axios
      .put(url, payload, headers)
      .then(async (response) => {
        dispatch(updateIssueSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateIssueFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { getIssues, updateIssue };

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { getProfile, setProfile } from "./../../store";
import { connect } from "react-redux";
import SidebarImage from "./SidebarImage";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [{ path: "/settings", state: "settings" }];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    let data = JSON.parse(localStorage.getItem("lmsLoginDetails"));
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html">
            <img src={require("../../assets/images/logo.svg")} alt="logo" />
          </a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html">
            <img
              src={require("../../assets/images/logo-mini.png")}
              alt="logo"
            />
          </a>
        </div>
        <ul className="nav">
          <li className="nav-item nav-profile not-navigation-link">
            <div className="nav-link">
              <Dropdown>
                <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                  <div className="d-flex justify-content-between align-items-start">
                    <div
                      className="profile-image "
                      style={{
                        height: "40px",
                        width: "fit-content",
                        marginTop: "-10px",
                      }}
                    >
                      <SidebarImage />
                    </div>
                    <div className="text-left ml-3">
                      <p className="profile-name">
                        {data !== null ? data.data.name : ""}
                      </p>
                      <small className="designation text-muted text-small">
                        {data !== null ? data.data.role : ""}
                      </small>
                      <span className="status-indicator online"></span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item
                    as={Link} 
                    to="/profile"
                    className="dropdown-item preview-item d-flex align-items-center text-small"
                  >
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/change_password"
                    className="dropdown-item preview-item d-flex align-items-center text-small"
                  >
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/logout"
                    className="dropdown-item preview-item d-flex align-items-center text-small"
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>
          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/clients") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/clients">
              <i className="fa fa-users menu-icon"></i>
              <span className="menu-title">Clients</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/lmsusers") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/lmsusers">
              <i className="fa fa-user-circle menu-icon"></i>
              {/* <i class="bi bi-person-circle"></i> */}
              <span className="menu-title"> Lms Users</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/mobillorusers")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/mobillorusers">
              <i className="fa fa-user menu-icon"></i>
              <span className="menu-title">Mobillor Users </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/products") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/products">
              <i className="fa fa-product-hunt menu-icon"></i>
              <span className="menu-title">Products</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/orders") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/orders">
              <i className="mdi mdi-cart menu-icon"></i>
              <span className="menu-title">Orders & Subscriptions</span>
            </Link>
          </li>
          
          <li
            className={
              this.isPathActive("/get_key") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/get_key">
              <i className="mdi mdi-key-plus menu-icon"></i>
              <span className="menu-title">Temporary Key</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/modules") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/modules">
              <i className="mdi mdi-view-module menu-icon"></i>
              <span className="menu-title">Modules</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/partners") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/partners">
              <i className="fa fa-handshake-o menu-icon"></i>
              <span className="menu-title">Partners</span>
            </Link>
          </li>
          
          <li
            className={
              this.isPathActive("/logout") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/logout">
              <i className="mdi mdi-lock-outline menu-icon"></i>
              <span className="menu-title">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

const mapStateToProps = (state) => {
  return {
    porfile: state.porfile,
    porfileSetReducer: state.porfileSetReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token, userId) => dispatch(getProfile(token, userId)),
    setProfile: (payload, token) => dispatch(setProfile(payload, token)),
  };
};

export default withRouter(Sidebar);

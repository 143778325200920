/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export { login } from "./user-management/login/loginActions";
export {
  clients,
  addClient,
  updateClient,
  deleteClient,
} from "./clients/clientsActions";

export { users, addUser, updateUser, deleteUser } from "./users/usersActions";

export { mobillorUsers } from "./mobillorusers/mobillorUsersActions";

export {
  products,
  addProduct,
  updateProduct,
  deleteProduct,
  getPrimeProducts,
  getSubProductsOfPrimeProduct,
  changeActiveStatusOfProduct,
  getProductDetailsById,
  getSubProductForPrimeProduct,
  deleteSubProductForPrimeProduct,
} from "./products/productsActions";
export {
  partners,
  addPartner,
  updatePartner,
  deletePartner,
} from "./partners/partnersActions";
export {
  orders,
  orderCreate,
  updateOrderStatus,
  updateSingleProductInOrder,
  deleteSingleProductInOrder,
  downloadFile,
  getPrimeProductsByClientId,
  getOrderDetailsByOrderId,
  getAdminUserDetails,
  getNEWPrimeProductsByClientId,
} from "./orders/ordersActions";
export { generateCustomKey } from "./custom-key/customKeyActions";
export {
  getLicenceDetails,
  generateLicenseKey,
  getLicenseKey,
  shareKey,
} from "./licence-details/licenceDetailsActions";
export { changePassword } from "./user-management/change-password/changePasswordActions";
export {
  getProfile,
  setProfile,
} from "./user-management/profile/profileActions";
export { getIssues, updateIssue } from "./Support/issueActions";
export {
  getModules,
  getModulesByProductId,
  createModule,
  updateModule,
  deleteModule,
} from "./modules/modulesActions";

export {
  renewSubscription,
  changeSubscriptionStatus,
} from "./subscription/subscriptionActions";

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const UPDATE_RENEWSUBSCRIPTION_REQUEST =
  "UPDATE_RENEWSUBSCRIPTION_REQUEST";
export const UPDATE_RENEWSUBSCRIPTION_SUCCESS =
  "UPDATE_RENEWSUBSCRIPTION_SUCCESS";
export const UPDATE_RENEWSUBSCRIPTION_FAILURE =
  "UPDATE_RENEWSUBSCRIPTION_FAILURE";

export const SUBSCRIPTION_STATUS_REQUEST = "SUBSCRIPTION_STATUS_REQUEST";
export const SUBSCRIPTION_STATUS_SUCCESS = "SUBSCRIPTION_STATUS_SUCCESS";
export const SUBSCRIPTION_STATUS_FAILURE = "SUBSCRIPTION_STATUS_FAILURE";

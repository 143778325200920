/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from "./changePasswordTypes";

import axios from "axios";
import Config from "../../../config/index";
let lmsUrl = Config.lmsUrl;

const changePasswordRequest = () => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
  };
};

const changePasswordSuccess = (payload) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: payload,
  };
};

const changePasswordFaliure = (error) => {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload: error,
  };
};

const changePassword = (token, payload) => {
  let newPayload = {
    userInfo: {
      userId: payload.userId,
    },
    data: {
      password: payload.password,
      passwordConfirmation: payload.passwordConfirmation,
    },
  };
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(changePasswordRequest());
    let url = `${lmsUrl}/users_management/users/reset_password`;
    return axios
      .put(url, newPayload, headers)
      .then((response) => {
        dispatch(changePasswordSuccess(response.data));
        return response.data;
      })
      .catch((err) => {
        dispatch(changePasswordFaliure(err.message));
        return err;
      });
  };
};

export { changePassword };

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import {
    GET_PARTNERS_REQUEST,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILURE,
    POST_PARTNERS_REQUEST,
    POST_PARTNERS_SUCCESS,
    POST_PARTNERS_FAILURE,
    UPDATE_PARTNER_REQUEST,
    UPDATE_PARTNER_SUCCESS,
    UPDATE_PARTNER_FAILURE,
    DELETE_PARTNER_REQUEST,
    DELETE_PARTNER_SUCCESS,
    DELETE_PARTNER_FAILURE
} from './partnersTypes'


const initialState = {
    loading: false,
    partners: [],
    masterPartners: [],
    error: ""
}

const partnersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PARTNERS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_PARTNERS_SUCCESS:
            return {
                loading: true,
                partners: action.payload.partners,
                masterPartners: action.payload.masterPartners,
                error: ''
            }
        case GET_PARTNERS_FAILURE:
            return {
                loading: false,
                partners: [],
                masterPartners: [],
                error: action.payload
            }
        default:
            return state
    }
}



const initialStateForAdd = {
    loading: false,
    partner: {},
    error: ""
}


const addPartnerReducer = (state = initialStateForAdd, action) => {
    switch (action.type) {
        case POST_PARTNERS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case POST_PARTNERS_SUCCESS:
            return {
                loading: true,
                partner: action.payload,
                error: ''
            }
        case POST_PARTNERS_FAILURE:
            return {
                loading: false,
                partner: {},
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateForUpdate = {
    loading: false,
    partner: {},
    error: ""
}

const updatePartnerReducer = (state = initialStateForUpdate, action) => {
    switch (action.type) {
        case UPDATE_PARTNER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_PARTNER_SUCCESS:
            return {
                loading: true,
                partner: action.payload,
                error: ''
            }
        case UPDATE_PARTNER_FAILURE:
            return {
                loading: false,
                partner: {},
                error: action.payload
            }
        default:
            return state
    }
}


const initialStateForDelete = {
    loading: false,
    partner: {},
    error: ""
}

const deletePartnerReducer = (state = initialStateForDelete, action) => {
    switch (action.type) {
        case DELETE_PARTNER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_PARTNER_SUCCESS:
            return {
                loading: true,
                partner: action.payload,
                error: ''
            }
        case DELETE_PARTNER_FAILURE:
            return {
                loading: false,
                partner: {},
                error: action.payload
            }
        default:
            return state
    }
}



export { partnersReducer, addPartnerReducer, updatePartnerReducer, deletePartnerReducer } 
/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/
import {
    GET_MODULES_REQUEST,
    GET_MODULES_SUCCESS,
    GET_MODULES_FAILURE,
    GET_MODULESBYPRODUCTID_REQUEST,
    GET_MODULESBYPRODUCTID_SUCCESS,
    GET_MODULESBYPRODUCTID_FAILURE,
    CREATE_MODULE_REQUEST,
    CREATE_MODULE_SUCCESS,
    CREATE_MODULE_FAILURE,
    UPDATE_MODULE_REQUEST,
    UPDATE_MODULE_SUCCESS,
    UPDATE_MODULE_FAILURE,
    DELETE_MODULE_REQUEST,
    DELETE_MODULE_SUCCESS,
    DELETE_MODULE_FAILURE
} from './modulesTypes'

const initialState = {
    loading: false,
    modules: {},
    error: ""
}

const getModulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MODULES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_MODULES_SUCCESS:
            return {
                loading: true,
                modules: action.payload,
                error: ''
            }
        case GET_MODULES_FAILURE:
            return {
                loading: false,
                modules: {},
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateForModulesByProductId = {
    loading: false,
    modulesbyproductid: {},
    error: ""
}

const getModulesByProductIdReducer = (state = initialStateForModulesByProductId, action) => {
    switch (action.type) {
        case GET_MODULESBYPRODUCTID_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_MODULESBYPRODUCTID_SUCCESS:
            return {
                loading: true,
                modulesbyproductid: action.payload,
                error: ''
            }
        case GET_MODULESBYPRODUCTID_FAILURE:
            return {
                loading: false,
                modulesbyproductid: {},
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateForCreate = {
    loading: false,
    module: {},
    error: ""
}

const createModuleReducer = (state = initialStateForCreate, action) => {
    switch (action.type) {
        case CREATE_MODULE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_MODULE_SUCCESS:
            return {
                loading: true,
                module: action.payload,
                error: ''
            }
        case CREATE_MODULE_FAILURE:
            return {
                loading: false,
                module: {},
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateForUpdate = {
    loading: false,
    module: {},
    error: ""
}

const updateModuleReducer = (state = initialStateForUpdate, action) => {
    switch (action.type) {
        case UPDATE_MODULE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_MODULE_SUCCESS:
            return {
                loading: true,
                module: action.payload,
                error: ''
            }
        case UPDATE_MODULE_FAILURE:
            return {
                loading: false,
                module: {},
                error: action.payload
            }
        default:
            return state
    }
}

const initialStateForDelete = {
    loading: false,
    module: {},
    error: ""
}

const deleteModuleReducer = (state = initialStateForDelete, action) => {
    switch (action.type) {
        case DELETE_MODULE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_MODULE_SUCCESS:
            return {
                loading: true,
                module: action.payload,
                error: ''
            }
        case DELETE_MODULE_FAILURE:
            return {
                loading: false,
                module: {},
                error: action.payload
            }
        default:
            return state
    }
}

export {
    getModulesReducer,
    getModulesByProductIdReducer,
    createModuleReducer,
    updateModuleReducer,
    deleteModuleReducer
}
/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  POST_USERS_REQUEST,
  POST_USERS_SUCCESS,
  POST_USERS_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
} from "./usersTypes";

import axios from "axios";
import Config from "../../config/index";
let lmsUrl = Config.lmsUrl;

const usersRequest = () => {
  return {
    type: GET_USERS_REQUEST,
  };
};

const usersSuccess = (payload) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: payload,
  };
};

const usersFaliure = (error) => {
  return {
    type: GET_USERS_FAILURE,
    payload: error,
  };
};

const users = (token) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(usersRequest());
    let url = `${lmsUrl}/users_management/users/users`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(usersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(usersFaliure(err.message));
      });
  };
};

const addUserRequest = () => {
  return {
    type: POST_USERS_REQUEST,
  };
};

const addUserSuccess = (payload) => {
  return {
    type: POST_USERS_SUCCESS,
    payload: payload,
  };
};

const addUserFaliure = (error) => {
  return {
    type: POST_USERS_FAILURE,
    payload: error,
  };
};

const addUser = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(addUserRequest());
    let url = `${lmsUrl}/users_management/users/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(addUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(addUserFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};

const updateUserSuccess = (payload) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: payload,
  };
};

const updateUserFaliure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: error,
  };
};

const updateUser = (token, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(updateUserRequest());
    let url = `${lmsUrl}/users_management/users/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(updateUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(updateUserFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const deleteUserRequest = () => {
  return {
    type: DELETE_USER_REQUEST,
  };
};

const deleteUserSuccess = (payload) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: payload,
  };
};

const deleteUserFaliure = (error) => {
  return {
    type: DELETE_USER_FAILURE,
    payload: error,
  };
};

const deleteUser = (token, user_id) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteUserRequest());
    let url = `${lmsUrl}/users_management/users/delete?user_id=${user_id}`;
    return axios
      .put(url, {}, headers)
      .then((response) => {
        dispatch(deleteUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteUserFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { users, addUser, updateUser, deleteUser };

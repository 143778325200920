/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  PROFILE_SET_REQUEST,
  PROFILE_SET_SUCCESS,
  PROFILE_SET_FAILURE,
} from "./profileTypes";

import axios from "axios";
import Config from "../../../config/index";
let lmsUrl = Config.lmsUrl;

const profileRequest = () => {
  return {
    type: PROFILE_REQUEST,
  };
};

const profileSuccess = (payload) => {
  return {
    type: PROFILE_SUCCESS,
    payload: payload,
  };
};

const profileFaliure = (error) => {
  return {
    type: PROFILE_FAILURE,
    payload: error,
  };
};

const getProfile = (token, userId) => {
  const params = new URLSearchParams({
    user_id: userId,
  }).toString();

  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(profileRequest());
    let url = `${lmsUrl}/users_management/users/profile?${params}`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(profileSuccess(response.data));
      })
      .catch((err) => {
        dispatch(profileFaliure(err.message));
      });
  };
};

const profileSetRequest = () => {
  return {
    type: PROFILE_SET_REQUEST,
  };
};

const profileSetSuccess = (payload) => {
  return {
    type: PROFILE_SET_SUCCESS,
    payload: payload,
  };
};

const profileSetFaliure = (error) => {
  return {
    type: PROFILE_SET_FAILURE,
    payload: error,
  };
};

const setProfile = (data, token) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(profileSetRequest());
    let url = `${lmsUrl}/users_management/users/set_profile`;
    return axios
      .put(url, data, headers)
      .then((response) => {
        dispatch(profileSetSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(profileSetFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { getProfile, setProfile };

/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_MOBILLOR_USERS_REQUEST,
  GET_MOBILLOR_USERS_SUCCESS,
  GET_MOBILLOR_USERS_FAILURE,
} from "./mobillorUsersTypes";

import axios from "axios";
import Config from "../../config/index";
let MobillorUrl = Config.MobillorUrl;

const mobillorUsersRequest = () => {
  return {
    type: GET_MOBILLOR_USERS_REQUEST,
  };
};

const mobillorUsersSuccess = (payload) => {
  return {
    type: GET_MOBILLOR_USERS_SUCCESS,
    payload: payload,
  };
};

const mobillorUsersFaliure = (error) => {
  return {
    type: GET_MOBILLOR_USERS_FAILURE,
    payload: error,
  };
};

const mobillorUsers = (token) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(mobillorUsersRequest());
    let url = `${MobillorUrl}/mobilloruser/get_users`;
    axios
      .get(url, headers)
      .then((response) => {
        dispatch(mobillorUsersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(mobillorUsersFaliure(err.message));
      });
  };
};

export { mobillorUsers };

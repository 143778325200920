import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { green, pink, blue } from "@material-ui/core/colors";
import { connect } from "react-redux";
import { getProfile, setProfile } from "../../store";
import Avatar from "@material-ui/core/Avatar";

const SidebarImage = ({
  porfile,
  getProfile,
  setProfile,
  porfileSetReducer,
}) => {
  let data = JSON.parse(localStorage.getItem("lmsLoginDetails"));
  let token = data ? data.token : "";
  let userId = data ? data.data["userId"] : "";
  const avtar = data ? data.data["name"].toUpperCase() : "";

  const lastName = avtar ? avtar.split(" ")[1] : "";

  const lastname = lastName ? lastName.slice(0, 1).toUpperCase() : "";

  let profile1 = data ? data.profile : "";
  useEffect(() => {
    getProfile(token, userId);
  }, [getProfile]);

  const [image, setImage] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const a = porfile.data.data;

  function randomColor() {
    var x = Math.floor(Math.random() * avtar.length);
    var y = 100 + Math.floor(Math.random() * 256);
    var z = 50 + Math.floor(Math.random() * 256);
    var bgColor = "rgb(" + x + "," + y + "," + z + ")";

    return bgColor;
  }

  const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
  };

  const h = normalizeHash(avtar.length, 0, 360);
  const s = normalizeHash(avtar.length, 0, 100);
  const l = normalizeHash(avtar.length, 0, 100);

  useEffect(() => {
    var { name, email, profile } = porfile.data.data ? porfile.data.data : {};
    setImage(profile);
    setemail(email);
    setname(name);
  }, [porfile]);

  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  return (
    <>
      {image ? (
        <>
          {" "}
          <img
            src={image}
            style={{
              width: "50px",
              height: "50px",
              marginTop: "0px",
            }}
            alt={<Avatar>{avtar ? avtar.slice(0, 1) : " "}</Avatar>}
          />
        </>
      ) : (
        <>
          <span>
            <Avatar
              sx={{ bgcolor: pink[500] }}
              style={{
                width: "50px",
                height: "50px",
                // backgroundColor: "#ede7f6",
                // color: "purple", 
                backgroundColor:"#fce4ec" , 
                color:"#f44336"
              }}
            >
              {data !== null
                ? data.data.name.slice(0, 1).toUpperCase() + lastname
                : ""}
            </Avatar>
          </span>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    porfile: state.porfile,
    porfileSetReducer: state.porfileSetReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token, userId) => dispatch(getProfile(token, userId)),
    setProfile: (payload, token) => dispatch(setProfile(payload, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarImage);
